import { ICommodityPrice } from './type'

export const getFullContractName = (item: ICommodityPrice): string => {
  const name = item.name
  const month = item.month ?? ''
  const year = item.year ? item.year.toString() : ''
  const shortMonth = month.slice(0, 3)
  const shortYear = year.slice(-2)
  return `${shortMonth} ${shortYear} ${name}`
}
