import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { MarketOverviewProps } from './type'
import { getFullContractName } from './utils'

const marketOverview = Bem('MarketOverview')
const headerCell = marketOverview('HeaderCell')
const contentCell = marketOverview('ContentCell')

export const MarketOverview = ({
  commodityPrices,
  children,
  className,
}: MarketOverviewProps) => {
  return (
    <div
      data-component="market-overview"
      className={marketOverview({}, [className])}
    >
      <table
        className={marketOverview('Table')}
        data-testid="market-overview-table"
      >
        <thead>
          <tr className={marketOverview('HeaderRow')}>
            <th
              className={marketOverview('HeaderCell', { variant: 'contract' })}
            >
              Contract
            </th>
            <th className={headerCell}>Last</th>
            <th className={headerCell}>Change</th>
            <th className={headerCell}>High</th>
            <th className={headerCell}>Low</th>
            <th className={headerCell}>Open</th>
            <th
              className={marketOverview('HeaderCell', { variant: 'lastTrade' })}
            >
              Last Trade
            </th>
          </tr>
        </thead>
        <tbody data-testid="table-body">
          {commodityPrices.map((commodityPrice, index) => {
            const {
              url,
              symbol,
              lastPrice,
              netChange,
              high,
              low,
              open,
              tradeTimestamp,
            } = commodityPrice
            return (
              <tr key={index} className={marketOverview('ContentRow')}>
                <td className={marketOverview('ContractCell')}>
                  <Link to={`/commodities/${url}?contract=${symbol}`}>
                    {getFullContractName(commodityPrice)}
                  </Link>
                </td>
                <td className={contentCell}>{lastPrice}</td>
                <td
                  className={contentCell}
                  data-testid="market-overview-change"
                >
                  {!netChange ? (
                    <span data-testid="market-overview-no-change">unch —</span>
                  ) : (
                    <div
                      className={marketOverview('ChangeContent', {
                        isGrow: netChange > 0,
                      })}
                    >
                      <span>{netChange > 0 ? `+${netChange}` : netChange}</span>
                      <Icon
                        icon={netChange > 0 ? 'ArrowUp' : 'ArrowDown'}
                        className={marketOverview('ChangeIcon', {
                          isArrowUp: netChange > 0,
                        })}
                      />
                    </div>
                  )}
                </td>

                <td className={contentCell}>{high}</td>
                <td className={contentCell}>{low}</td>
                <td className={contentCell}>{open}</td>
                <td className={marketOverview('LastTradeCell')}>
                  {new Date(tradeTimestamp).toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {children}
    </div>
  )
}
