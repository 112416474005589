import { Ads } from '@scm/ads'
import { EventType, GTM } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { MarketOverview } from '@scm/ui-component-market-overview'
import { Bem } from '@scm/ui-core'

import { CommodityPricesProps } from './type'

const commodityPricesModule = Bem('CommodityPrices')

const MODULE_UID = 'module_commodity_prices'

export const CommodityPrices = (props: CommodityPricesProps) => {
  const {
    variant,
    commodityPrices,
    ctaButtonLink,
    ctaButtonText,
    ctaButtonLink2,
    ctaButtonText2,
    pricesTitle,
    title,
    moduleLocation,
    level = 'level1',
  } = props
  const sendEvent = GTM.hooks.useSendEvent()
  const url = GTM.utils.getCurrentUrl()
  const sponsorAdStatus = Ads.hooks.useIsAdRender(
    'sponsoredlogo_commodities_1v',
  )
  //limit 10 items if level2
  const commodityPricesData =
    level === 'level2' && commodityPrices
      ? commodityPrices.slice(0, 10)
      : commodityPrices

  const trackGtmClick = (href: string) => {
    const isNotCommodityModule = variant !== 'default'
    if (!url || !href || isNotCommodityModule) return
    const payload = {
      name: title || '',
      location: moduleLocation || '',
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  return (
    <div data-module="commodity-prices" className={commodityPricesModule()}>
      <div
        className={commodityPricesModule('Title', {
          level,
          variant,
        })}
        data-testid="commodity-prices-title"
      >
        {pricesTitle || 'Market Overview'}
      </div>
      <div
        className={commodityPricesModule('MarketInfoWrapper', {
          level,
          variant,
        })}
      >
        <MarketOverview
          commodityPrices={commodityPricesData || []}
          className={commodityPricesModule('MarketOverview', { variant })}
        />
        <div className={commodityPricesModule('ButtonsWrapper')}>
          {ctaButtonLink && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              className={commodityPricesModule('Button', { primary: true })}
              to={ctaButtonLink}
              data-testid="commodity-prices-primary-btn"
              trackGtmClick={trackGtmClick}
            >
              {ctaButtonText}
            </Link>
          )}
          {ctaButtonLink2 && (
            <Link
              className={commodityPricesModule('Button', { secondary: true })}
              to={ctaButtonLink2}
              data-testid="commodity-prices-secondary-btn"
              trackGtmClick={trackGtmClick}
            >
              {ctaButtonText2}
            </Link>
          )}
        </div>
        {variant == 'default' && (
          <div className={commodityPricesModule('SponsorAdWrapper')}>
            {sponsorAdStatus === 'show' && (
              <p className={commodityPricesModule('SponsorAdTitle')}>
                BROUGHT TO YOU BY
              </p>
            )}
            <Ads.Ad
              pos="sponsoredlogo_commodities_1v"
              className={commodityPricesModule('SponsorAd')}
            />
          </div>
        )}
        <CopyRightSection {...props} />
      </div>
    </div>
  )
}

export const CopyRightSection = ({
  copyRightInformation,
  variant,
  level,
}: CommodityPricesProps) => {
  if (!copyRightInformation) return null
  const { logoImage, logoImageAltText, copyrightNotice } = copyRightInformation
  return (
    <div
      className={commodityPricesModule('CopyrightWrapper', {
        level,
        variant,
      })}
      data-testid="commodity-prices-copyright"
    >
      <Image
        className={commodityPricesModule('CopyrightImage')}
        src={logoImage}
        alt={logoImageAltText}
        title={logoImageAltText}
        fallback="brand"
      />

      <div
        className={commodityPricesModule('CopyrightNotice')}
        dangerouslySetInnerHTML={{
          __html: copyrightNotice,
        }}
      />
    </div>
  )
}
